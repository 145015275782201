import getConfig from 'next/config'

export const config = {
  app: {
    name: 'thirsty-web',
  },
  thirstyathome: {
    enabled: true,
    regions: ['VIC'],
  },
  google: {
    apiKey: 'AIzaSyD70o12WpgtDR13iV3fQWBURpmWhkEZmzY',
  },
  googlePayMerchantId: process.env.NEXT_PUBLIC_GOOGLE_PAY_MERCHANT_ID || '123456789',
  ...getConfig(),
}

console.log(config)
